import patientAccountPb from "../modules/core/grpc/generated/patientAccount_pb";
import patientAccountService from "../modules/core/services/patientAcountService"

export const VerifyContinueRegister = async (obj: any) => {
    try {
        const req = new patientAccountPb.VerifyContinueRegisterRequest();
        req.setEmail(obj.email);
        req.setToken(obj.token);

        const res = await patientAccountService.verifyContinueRegister(req);
        console.log(res);

        

        return res;
    } catch (error) {
        return error;
    }
}